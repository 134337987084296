import React, { useRef, useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CBtnList,
  CSectTitle03,
  CMedia,
  CHorizCard,
  CHeroImg,
  CCard,
  CFaqList,
  LContact03,
} from '../../../components/_index';
import infoChoice from '../../../utils/info-choice';
import infoGet from '../../../utils/info-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        type="ja"
        data={{
          title: {
            main: 'アクセシブルルーム',
            sub: <>ACCESSIBLE ROOM</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/accessible/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/accessible/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <section className="l_sect">
        <LWrap>
          <p className="c_headingLv2">
            安心してお過ごしいただくための、お部屋とサービスをご用意
          </p>
          <p className="c_sectLead">
            段差がなく使いやすさに配慮した客室で、安心・安全にお過ごしいただけます。また、各種サービスもご用意しております。
          </p>
          <CSectTitle03 title="客室のご案内" />
          <CMedia
            data={[
              {
                img: {
                  src: '/assets/images/stay/accessible/img_roominfo.png',
                  alt: '',
                },
                title: (
                  <>
                    スタンダードフロア
                    <br />
                    アクセシブルルーム（ツイン）
                  </>
                ),
                text: (
                  <>
                    すべてのお客様に使いやすいお部屋です。安全･快適なご滞在となりますよう、客室設備はもちろん、スタッフ一同お客様にご満足いただけるサービスをご提供してまいります。
                    <ul className="c_noteList">
                      <li>
                        お部屋の数に限りがありますので、
                        <a href="#contact">こちら</a>
                        までお問合せください。
                      </li>
                    </ul>
                  </>
                ),
                btn: {
                  label: 'スタンダードフロア',
                  link: {
                    href: '/stay/floor/standard_floor/',
                  },
                  color: 'bgWhite03',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect u_bgGray">
        <LWrap>
          <CSectTitle03 title="客室の仕様" />
          <CHeroImg
            exClass="u_mb40"
            img={{
              src: '/assets/images/stay/accessible/img_roomspec.png',
              alt: '',
            }}
          />
          <CHorizCard
            data={[
              {
                img: {
                  src: '/assets/images/stay/accessible/img_roomspec_sub.png',
                  alt: '',
                },
                title: <>ベッド</>,
                text: <>通常の配置から向きを変更可能です。</>,
              },
              {
                img: {
                  src: '/assets/images/stay/accessible/img_roomspec_sub02.png',
                  alt: '',
                },
                title: <>ドアモニター</>,
                text: <>カメラ内蔵のドアチャイムを設置しております。</>,
              },
            ]}
          />
          <CHeroImg
            exClass="u_mb40"
            img={{
              src: '/assets/images/stay/accessible/img_roomspec02.png',
              alt: '',
            }}
          />
          <CHeroImg
            exClass="u_mb40"
            img={{
              src: '/assets/images/stay/accessible/img_roomspec02_02.png',
              alt: '',
            }}
          />
          <CHorizCard
            data={[
              {
                img: {
                  src: '/assets/images/stay/accessible/img_roomspec02_sub.png',
                  alt: '',
                },
                title: <>洗面台</>,
                text: (
                  <>
                    鏡は斜めに設置し使いやすく、緊急呼び出しボタンはトイレットペーパーホルダー脇に設置しております。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/stay/accessible/img_roomspec02_sub02.png',
                  alt: '',
                },
                title: <>トイレ</>,
                text: (
                  <>
                    トイレには手すりを設置しております。洗浄ボタンはトイレットペーパーホルダー横にございます。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/stay/accessible/img_roomspec02_sub03.png',
                  alt: '',
                },
                title: <>バスタブ</>,
                text: (
                  <>
                    バスチェア（高さ
                    15~22.5㎝の2.5㎝刻み）のご用意をしております。バスタブ用手すり、お貸出をしております。
                  </>
                ),
              },
            ]}
          />
          <CHeroImg
            exClass="u_mb40"
            img={{
              src: '/assets/images/stay/accessible/img_roomspec03.png',
              alt: '',
            }}
          />
          <CHorizCard
            exClass="u_mb40"
            data={[
              {
                img: {
                  src: '/assets/images/stay/accessible/img_roomspec03_sub.png',
                  alt: '',
                },
                title: <>入口</>,
                text: (
                  <>
                    カードキー、取っ手、新聞受けは取り出しやすい位置に設置しております。ドアは内開きです。
                  </>
                ),
              },
            ]}
          />
          <div style={{ maxWidth: '520px' }}>
            <CHeroImg
              exClass="u_mb0"
              img={{
                src: '/assets/images/stay/accessible/img_roomspec03_02.png',
                alt: '',
              }}
            />
          </div>
        </LWrap>
      </section>
      <section className="l_sect">
        <LWrap>
          <CSectTitle03 title="サービス" />
          <ul className="c_noteList u_tac u_mb40">
            <li>一般のお部屋でもご利用をいただけます。</li>
          </ul>
          <CCard
            col={2}
            data={[
              {
                img: {
                  src: '/assets/images/stay/accessible/img_universalservice.png',
                  alt: '',
                },
                title: <>貸出備品</>,
                content: (
                  <>
                    耳の不自由な方のために振動式ドアノックセンサー、アラームセンサーなどをご用意しております。筆談器はフロントにてご用意しております。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/stay/accessible/img_universalservice02.png',
                  alt: '',
                },
                title: <>補助犬（盲導犬・介助犬・補導犬）</>,
                content: (
                  <>
                    補助犬（盲導犬・介助犬・補導犬）を全館で受入可能です。ご宿泊・レストランご利用の際は事前にご相談ください。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mb100">
        <LWrap>
          <CSectTitle03 title="よくあるご質問" />
          <CFaqList
            data={[
              {
                q: '車椅子のまま利用できるレストランはありますか?',
                a: <>全レストランにてご利用いただけます。</>,
              },
              {
                q: '車椅子の貸出はありますか?',
                a: (
                  <>
                    お貸し出しは可能ですが、数に限りがございます。事前にお問合せください。
                  </>
                ),
              },
              {
                q: '車椅子対応の化粧室はありますか?',
                a: <>1F バリアフリートイレをご利用ください。</>,
              },
            ]}
          />
          <CBtnList
            exClass="u_mt30"
            data={[
              {
                label: 'よくあるご質問',
                link: {
                  href: '/faq/accessibility/',
                },
                color: 'bgWhite03',
              },
            ]}
          />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宿泊',
              path: '/stay/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <div id="contact">
        <LContact03
          title="ご予約・お問合せ"
          btn={{
            btn: {
              label: 'お問合せフォーム',
              link: {
                href: 'https://contact.royalparkhotels.co.jp/rph/contact?_ifbs-rph_contact_form=s1_Step1',
                blank: true,
              },
            },
          }}
          tel={{
            number: '03-5641-2958',
            note: '宿泊営業課（受付時間 平日 8:00～20:00／土・日・祝日 9:00～18:00）',
          }}
        />
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
